import { Badge } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoginMsgContext } from '../../router/router';
import { getText } from '../../utils/util';
import './index.css';

export default function BottomBar({ index }) {
  const { t: translate } = useTranslation();
  const [loginmsg, setloginmsg] = useContext(LoginMsgContext);
  const navigate = useNavigate();
  const companySkin = localStorage.getItem('companySkin');
  return (
    <div className="md:!hidden bottomBar-1">
      <div
        className={`bottomBar-2 ${index === 1 ? 'act' : ''}`}
        onClick={() => {
          navigate('/pc-home');
        }}
      >
        <div class="bottomBar-3 img-box">
          <img src="/1.png" alt="" />
        </div>
        <div class={index == 1 ? 'bottomBar-7' : 'bottomBar-14'}>
          <span class="bottomBar-8">{translate(getText('首页'))}</span>
        </div>
      </div>
      <div
        className={`bottomBar-9 ${index === 2 ? 'act' : ''}`}
        onClick={() => {
          navigate('/quotation');
        }}
      >
        <div class="bottomBar-10 img-box">
          <img src="/2.png" alt="" />
        </div>
        <div class={index == 2 ? 'bottomBar-7' : 'bottomBar-14'}>
          <span class="bottomBar-15">{translate(getText('行情'))}</span>
        </div>
      </div>
      <div
        className={`bottomBar-16 ${index === 3 ? 'act' : ''}`}
        onClick={() => {
          navigate('/trade/btc');
        }}
      >
        <div class="bottomBar-17 img-box">
          <img src="/3.png" alt="" />
        </div>
        <div class={index == 3 ? 'bottomBar-7' : 'bottomBar-14'}>
          <span class="bottomBar-22">{translate(getText('交割合约'))}</span>
        </div>
      </div>

      {/* 永续 start */}
      {/* <div
        className={`bottomBar-23 ${index === 4 ? 'act' : ''}`}
        onClick={() => {
          navigate('/lever/btc')
        }}>
        <div class='bottomBar-24 img-box'>
          <svg
            width='.88rem'
            height='.88rem'
            viewBox='0 0 27 28'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <title>Sustain切图</title>
            <g id='页面-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.883324033'>
              <g
                id='Home'
                transform='translate(-512.000000, -1489.000000)'
                fill={index === 4 ? 'var(--them-color-white-bg)' : 'var(--them-color-gray-text)'}
                fill-rule='nonzero'>
                <g id='编组-9' transform='translate(0.000000, 1458.000000)'>
                  <g id='编组-12备份-2' transform='translate(490.000000, 19.000000)'>
                    <g id='Sustain' transform='translate(22.000000, 12.000000)'>
                      <g id='编组' transform='translate(4.000000, 3.000000)'>
                        <path
                          d='M17.7414134,3.90549076e-06 L1.2434153,3.90549076e-06 C0.561053244,3.90549076e-06 0,0.572038564 0,1.2677516 L0,20.7322562 C0,21.4279693 0.561053244,22.0000039 1.2434153,22.0000039 L17.756577,22.0000039 C18.4389391,22.0000039 19,21.4279693 19,20.7322562 L19,1.28321189 C19.0008208,0.942626778 18.8684869,0.615747816 18.6322778,0.374917035 C18.3960686,0.134086254 18.0754626,-0.000836811532 17.7414134,3.90549076e-06 Z M7.85474542,18.5368951 L3.50279188,18.5368951 C3.07821104,18.5368951 2.72944822,18.1813085 2.72944822,17.7484203 C2.72944822,17.3155322 3.07821104,16.9599456 3.50279188,16.9599456 L7.83958182,16.9599456 C8.26416265,16.9599456 8.61292548,17.3155322 8.61292548,17.7484203 C8.61292548,18.1813085 8.27932625,18.5368951 7.85474542,18.5368951 Z M11.5698277,14.7027433 L3.50279188,14.7027433 C3.07821104,14.7027433 2.72944822,14.3471566 2.72944822,13.9142685 C2.72944822,13.4813804 3.07821104,13.1257937 3.50279188,13.1257937 L11.5546641,13.1257937 C11.9792449,13.1257937 12.3280078,13.4813804 12.3280078,13.9142685 C12.3280078,14.3471566 11.9944085,14.7027433 11.5698277,14.7027433 Z M15.28491,9.63176829 C15.1636012,9.70906974 14.9968016,9.64722858 14.9968016,9.64722858 L12.5402982,8.5804686 C12.4038258,8.51862744 12.2673534,8.51862744 12.130881,8.5804686 L9.71986837,9.66268887 C9.71986837,9.66268887 9.56823236,9.69360945 9.49241435,9.63176829 C9.41659634,9.56992713 9.40143274,9.43078453 9.40143274,9.43078453 L9.40143274,1.31413247 L15.3758916,1.31413247 L15.3758916,9.38440366 C15.3758916,9.38440366 15.3910552,9.55446684 15.28491,9.63176829 L15.28491,9.63176829 Z'
                          id='形状'></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class={index == 4 ? 'bottomBar-7' : 'bottomBar-14'}>
          <span class='bottomBar-29'>{translate(getText('永续'))}</span>
        </div>
      </div> */}
      {/* 永续 end */}

      {/* 永续注释，改成 followup */}
      {/* followup start */}
      <div
        className={`bottomBar-23 ${index === 4 ? 'act' : ''}`}
        onClick={() => {
          navigate('/followup/btc');
        }}
      >
        <div class="bottomBar-24 img-box">
          <img src="/4.png" alt="" />
        </div>
        <div class={index == 4 ? 'bottomBar-7' : 'bottomBar-14'}>
          <span class="bottomBar-29">{translate(getText('跟單'))}</span>
        </div>
      </div>
      {/* followup end */}

      {/* 资产 start */}
      <div
        className={`bottomBar-30 ${index === 5 ? 'act' : ''}`}
        onClick={() => {
          navigate('/propertycenter');
        }}
      >
        <div class="bottomBar-31 img-box">
          <img src="/5.png" alt="" />
        </div>
        <div class={index == 5 ? 'bottomBar-7' : 'bottomBar-14'}>
          <span class="bottomBar-36">{translate(getText('资产'))}</span>
        </div>
      </div>
      {/* 资产 end */}
    </div>
  );
}

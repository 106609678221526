import { css } from '@emotion/css';
import classNames from 'classnames';
import Form, { Field } from 'rc-field-form';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

// import { EMAIL_LOGIN_REGEX } from "@const/regex";
// import { LOGIN_EMAIL_CONFIRMATION, LOGIN_EMAIL_SEND, LOGIN_RESET_PASSWORD, LOGIN_SUSPICIOUS, WRONG_EMAIL_PASSWORD } from "@const/errno";
// import { FORGET_PASSWORD } from "@const/moengage-analytic-event";
// import useAuth, { useGoogleUri, useLogin, useLoginCallback } from "@hooks/useAuth";
// import useSettings from "@hooks/useSettings";
// import useError from "@hooks/useError";
import { Button } from '@components/button';
import Container from '@components/container';
import { Input, InputPassword } from '@components/input';
import { Link } from '@components/link';
import styled from '@emotion/styled';
import useGlobalLoading from '@hooks/useGlobalLoading';
import { Header } from '@components/header';
import { getText } from '@utils/util';
// import AppleLoginButton from "@components/AppleLoginButton";
// import OpenInAppSheet from "@components/OpenInAppSheet";
// import { useRouter } from "next/router";
// import { useMediaQuery } from "@/utils/use-media-query";
// import useDeviceId from "@hooks/useDeviceId";
// import useGoogleReCaptcha from "@hooks/useRecaptcha";
// import GoogleIcon from "./svg/google.svg";
// import ModalLogin2fa from "./components/ModalLogin2fa";

const LoginWrapper = tw.section`
    py-4 xl:pt-12 xl:pb-20 
        bg-background dark:bg-dark-background
`;

const LoginBox = tw.div`
    flex flex-col items-center gap-6 xl:gap-8 
    mx-auto xl:p-8
    xl:max-w-[457px]
    xl:border border-main dark:border-dark-main
    xl:rounded-3xl 
`;

const Footer = styled.div`
  ${tw`
        flex items-center justify-center mt-[90px] xl:mt-8 gap-1
        py-4
        text-additional dark:text-dark-additional
    `}

  a {
    ${tw`m-0 text-primary dark:text-dark-primary `}
  }
`;

const Label = tw.label`
    text-main dark:text-dark-main
`;

const Space = tw.div`
    flex flex-col gap-4
`;

const ActionSpace = tw(Space)`gap-6 xl:gap-8`;

const StyledLink = tw(Link)`
    text-primary text-right
    mt-4 mb-8 self-end
    capitalize
`;

const Error = tw.span`
    text-error dark:text-dark-error pt-1
`;

const Divider = tw.div`
    flex items-center text-center gap-3 my-2 text-additional dark:text-dark-additional uppercase
`;

const groupCss = css`
  margin-bottom: 0 !important;
`;

interface LoginForm {
  email: string;
  password: string;
}

export const LoginBoxComponent = ({
  doLogin,
}: {
  doLogin: (data: { username: string; password: string }) => void;
}) => {
  const { t } = useTranslation();
  // const { login } = useLogin();
  // const { auth } = useAuth();
  // const { google, isLoading: isGoogleUriLoading } = useGoogleUri();
  // const { settings, isLoading: isSettingsLoading } = useSettings();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const {
  //   error: { code: errorCode },
  //   resetError,
  // } = useError();
  const { loading } = useGlobalLoading();
  // const { deviceId, isLoading: deviceIdLoading } = useDeviceId();
  // const { handleLoginCallback } = useLoginCallback();
  // const [error, setError] = useState<string>("");
  const isMobile = false;
  const isLoading = false;
  // const router = useRouter();
  // const { code } = router.query;
  const handleSubmit = async (values: LoginForm) => {
    loading.start();
    console.log('values', values);
    const { email, password } = values;
    doLogin({
      username: email,
      password,
    });
    // const recaptcha =
    //   settings?.recaptcha && (await executeRecaptcha?.("/login"));
    // const formData: LoginPayload = {
    //   email,
    //   password,
    //   c: recaptcha || "",
    // };
    // const res = await login(formData);
    // if (res?.error) {
    //   setError(res?.error?.message);
    // } else {
    //   resetError();
    // }
    loading.stop();
  };
  // const fieldChanges = () => {
  //   if (error) {
  //     setError("");
  //   }
  // };
  // const isLoginErrorValidation =
  //   errorCode === LOGIN_EMAIL_CONFIRMATION ||
  //   errorCode === LOGIN_EMAIL_SEND ||
  //   errorCode === LOGIN_SUSPICIOUS ||
  //   errorCode === LOGIN_RESET_PASSWORD;
  // const onLoginCallback = () =>
  //   handleLoginCallback({
  //     code: code as string,
  //   });
  // useEffect(() => {
  //   if (code && !isMobile && !auth.isLoggedIn) {
  //     if (deviceId) {
  //       onLoginCallback();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [code, isMobile, auth, deviceId]);
  return (
    <>
      <Header />
      <LoginWrapper>
        <Container>
          <LoginBox>
            <h1 className="heading-6 xl:heading-5 text-bold dark:text-dark-bold">
              {t('common.sign_in')}
            </h1>
            <Form
              onFinish={handleSubmit}
              // onFieldsChange={fieldChanges}
              className="w-full flex flex-col"
            >
              {(_, forms) => {
                const emailError = forms.getFieldError('email')[0];
                const passwordError = forms.getFieldError('password')[0];
                return (
                  <>
                    <Space>
                      <Field
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t(getText('请输入账号')),
                          },
                          // {
                          //   pattern: EMAIL_LOGIN_REGEX,
                          //   message: t("common.validation.invalid_email"),
                          // },
                        ]}
                      >
                        <Input
                          size="md"
                          // className={
                          //   isLoginErrorValidation ||
                          //   errorCode === WRONG_EMAIL_PASSWORD
                          //     ? "!border-error dark:!border-dark-error"
                          //     : ""
                          // }
                          groupClassName={groupCss}
                          label={<Label>{t(getText('邮箱或者号码'))}</Label>}
                          placeholder={t(getText('请输入账号'))}
                          error={emailError}
                          errorClassName="!paragraph-4 !font-normal"
                        />
                      </Field>
                      <Field
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t('login.password.required'),
                          },
                        ]}
                      >
                        <InputPassword
                          error={passwordError}
                          autoComplete="new-password"
                          label={<Label>{t('login.password.label')}</Label>}
                          placeholder={t('请输入密码')}
                          groupClassName={groupCss}
                          className={classNames({
                            // "!border-error dark:!border-dark-error":
                            //   isLoginErrorValidation ||
                            //   errorCode === WRONG_EMAIL_PASSWORD,
                          })}
                          errorClassName="!paragraph-4 !font-normal"
                        />
                      </Field>
                    </Space>
                    {/* <When condition={error}>
                      <Error>{!isLoginErrorValidation ? error : null}</Error>
                    </When> */}
                    <StyledLink
                      href="/reloadpassword"
                      onClick={async () => {
                        // const { default: moengageAnalytic } = await import(
                        //   "@lib/moengage-analytic"
                        // );
                        // moengageAnalytic.track(FORGET_PASSWORD);
                      }}
                    >
                      {t('login.password.forget')}
                    </StyledLink>

                    <ActionSpace>
                      <Button
                        type="submit"
                        block
                        className="text-base leading-6 tracking-[-0.2px] font-bold !bg-[#409197]"
                      >
                        {t('login.sign_in')}
                      </Button>
                      <Divider className="before:content-[''] after:content-[''] before:relative after:relative before:w-1/2 after:w-1/2 before:border-t after:border-t  before:border-main after:border-main dark:before:border-dark-main dark:after:border-dark-main">
                        {t('common.or')}
                      </Divider>
                      <a
                        href="/register"
                        className=" font-semibold rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 px-4 py-2 text-base bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500 w-full text-base leading-6 tracking-[-0.2px] font-bold text-center"
                      >
                        {t('login.sign_up')}
                      </a>
                      <Space></Space>
                    </ActionSpace>
                    {/* <Footer>
                      {t("login.dont_have_account")}
                      <StyledLink href="/register">
                        {t("login.sign_up")}
                      </StyledLink>
                    </Footer> */}
                  </>
                );
              }}
            </Form>
          </LoginBox>
        </Container>
      </LoginWrapper>
    </>
  );
};
// export default Login;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getText } from '../../../../utils/util';
import copy from 'copy-to-clipboard';
import { Cell, Dialog } from 'react-vant';
import './index.css';
import { Button } from 'antd';
import { Toast } from 'antd-mobile';
import { useState } from 'react';
import { imageConfig } from '../../../../config/config';
import RechargeCard from '../RechargeCard';

export default function CenterPage({ coinList }) {
  const navigate = useNavigate();
  const lan = localStorage.getItem('i18n');
  const c2ctxStatus = localStorage.getItem('c2ctxStatus');
  const { t: translate } = useTranslation();
  const companySkin = localStorage.getItem('companySkin');

  const getNode = () => {
    const nodes = [];
    for (let i = 0; i < coinList.length; i++) {
      const data = coinList[i];
      const node = (
        <div
          className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:-translate-y-0.5 cursor-pointer mb-4 w-full sm:w-[48%] lg:w-[31%] xl:w-[23%]"
          onClick={() => {
            navigate(`/recharge/${data?.id}`);
          }}
        >
          <div className="p-3 md:p-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-3">
              <div className="flex items-center gap-2 sm:gap-3 w-full sm:w-auto">
                <img
                  src={imageConfig.baseImageUrl + data?.img}
                  className="w-8 h-8 sm:w-10 sm:h-10 object-contain"
                  alt={data.name}
                />
                <div className="text-sm sm:text-base font-medium text-gray-800 break-all">
                  {`${data.name.toUpperCase()}${
                    data.czline ? `(${data.czline})`.toUpperCase() : ''
                  }`}
                </div>
              </div>
              <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto gap-2">
                <span className="px-2 sm:px-3 py-1 sm:py-1.5 bg-gray-50 rounded-full text-xs sm:text-sm text-gray-600 hover:bg-gray-100 whitespace-nowrap">
                  {translate(getText('前往購買'))}
                </span>
                <i className="fas fa-chevron-right text-gray-400"></i>
              </div>
            </div>
          </div>
        </div>
      );
      nodes.push(node);
    }
    return nodes;
  };

  return (
    <div className="w-full px-4 sm:px-6 py-4">
      <div className="max-w-7xl mx-auto">
        <div className="bg-blue-50 rounded-lg p-4 mb-6">
          <p className="text-sm text-blue-700">
            {translate(
              getText(
                '請如實填寫實際轉賬金額，否則可能導致系統審核無法通過，影響充值進度。'
              )
            )}
          </p>
        </div>
        <p className="text-base font-medium text-gray-700 mb-4">
          {translate(getText('請選擇以下充值通道'))}
        </p>
        <div className="flex flex-wrap justify-start gap-4">
          {coinList.map((data) => (
            <RechargeCard
              key={data.id}
              data={data}
              translate={translate}
              onCardClick={(id) => navigate(`/recharge/${id}`)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

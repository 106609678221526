import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import { imageConfig } from '@config/config';
import { getText } from '@utils/util';

import { useTranslation } from 'react-i18next';

const RechargeCard: React.FC<any> = ({ data, onCardClick }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rechargeAmount, setRechargeAmount] = useState<string>('');
  const { t: translate } = useTranslation();

  const handleCardClick = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    if (Number(rechargeAmount) >= (data?.czminnum || 0)) {
      localStorage.setItem('faith-rechargeAmount', rechargeAmount);
      onCardClick(data.id);
      setIsModalVisible(false);
      setRechargeAmount('');
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setRechargeAmount('');
  };

  const isAmountValid =
    rechargeAmount !== '' && Number(rechargeAmount) >= (data?.czminnum || 0);

  return (
    <>
      <div
        className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:-translate-y-0.5 cursor-pointer mb-4 w-full sm:w-[48%] lg:w-[31%] xl:w-[23%]"
        onClick={handleCardClick}
      >
        <div className="p-3 md:p-4">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-3">
            <div className="flex items-center gap-2 sm:gap-3 w-full sm:w-auto">
              <img
                src={imageConfig.baseImageUrl + data.img}
                className="w-8 h-8 sm:w-10 sm:h-10 object-contain"
                alt={data.name}
              />
              <div className="text-sm sm:text-base font-medium text-gray-800 break-all">
                {`${data.name.toUpperCase()}${
                  data.czline ? `(${data.czline})`.toUpperCase() : ''
                }`}
              </div>
            </div>
            <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto gap-2">
              <span className="px-2 sm:px-3 py-1 sm:py-1.5 bg-gray-50 rounded-full text-xs sm:text-sm text-gray-600 hover:bg-gray-100 whitespace-nowrap">
                {translate(getText('前往購買'))}
              </span>
              <i className="fas fa-chevron-right text-gray-400"></i>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={translate(getText('充值金额'))}
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        maskClosable={false}
        width={320}
        style={{ top: '30%' }}
        className="custom-modal"
      >
        <div className="p-4">
          <Input
            type="number"
            placeholder={`${translate(getText('最小充值金额'))}：${
              data?.czminnum || 0
            }`}
            value={rechargeAmount}
            onChange={(e) => setRechargeAmount(e.target.value)}
            className="mb-4"
          />
          <div className="text-xs text-gray-500 mb-4">
            {!isAmountValid && rechargeAmount && (
              <span className="text-red-500">
                {translate(getText('充值金额不能低于'))} {data?.czminnum || 0}
              </span>
            )}
          </div>
          <div className="flex justify-center">
            <Button
              type="primary"
              onClick={handleModalOk}
              disabled={!isAmountValid}
              style={{
                backgroundColor: isAmountValid ? '#19a8ac' : '#f5f5f5',
                color: isAmountValid ? 'white' : 'rgba(0, 0, 0, 0.25)',
              }}
            >
              {translate(getText('立即充值'))}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RechargeCard;

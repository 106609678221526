import { contractApi } from '@/api/contract-api';
import { leverApi } from '@/api/lever-api';
import ExtractOrderList from '@/pages/extractorderlist/components/ExtractOrderList';
import RechargeOrderList from '@/pages/rechargeorderlist/components/RechargeOrderList';
import { WSContext } from '@/router/router';
import { useCoinList } from '@hooks/useCoinList';
import { getText } from '@utils/util';
import { Toast } from 'antd-mobile';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDown } from './icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from './icons/ArrowUp.svg';
import { ReactComponent as ChevronRight } from './icons/ChevronRight.svg';
import { ReactComponent as CryptoCurrency } from './icons/CryptoCurrency.svg';
import { ReactComponent as CurrencyDollarCircle } from './icons/CurrencyDollarCircle.svg';
import { ReactComponent as CurrencyRupiahCircle } from './icons/CurrencyRupiahCircle.svg';
import { ReactComponent as FilterFunnel } from './icons/FilterFunnel.svg';
import { ReactComponent as GeoAlt } from './icons/geo-alt.svg';
import { ReactComponent as NoCoinAvailable } from './icons/NoCoinAvailable.svg';
import './index.css';

const MyWallet: React.FC<{ userInfo: any; mockUserInfo: any }> = ({
  userInfo,
  mockUserInfo,
}) => {
  const c2ctxStatus = localStorage.getItem('c2ctxStatus');
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const lan = localStorage.getItem('i18n');
  const companySkin = localStorage.getItem('companySkin');
  const propertyType = localStorage.getItem('propertyType');
  const { coinList } = useCoinList();

  const [coinListData] = useContext(WSContext);
  const btcData = coinListData?.['btc'];
  const ethData = coinListData?.['eth'];

  const btcClose = btcData?.close ?? 1;
  const ethClose = ethData?.close ?? 1;

  const usdt =
    Number(propertyType) === 1 ? userInfo?.usdt : mockUserInfo?.money;

  const [hyorders, sethyorders] = useState([] as any[]);

  const uid = localStorage.getItem('uid');
  //加载数 据
  const loadhyorderData = async () => {
    const data = await contractApi.gethyorder({ uid });
    if (data.ok) {
      sethyorders(data.data);
    }
  };
  useEffect(() => {
    loadhyorderData();
  }, []);

  // State for active tab
  const [activeTab, setActiveTab] = useState<'contract' | 'perpetual'>(
    'contract'
  );

  //PerpetualBox
  const [leverorders, setleverorders] = useState([] as any[]);

  //订单信息
  const loadLeverListData = async () => {
    const data = await leverApi.list({ uid, pageNum: 1, pageSize: 200 });
    if (data.ok) {
      setleverorders(data.data.records);
    }
  };

  useEffect(() => {
    loadLeverListData();
  }, []);

  //平仓
  const closeorder = async (lid, num, ploss) => {
    const data = await leverApi.closeorder({ uid, lid, num, ploss });
    if (data.ok) {
      Toast.show({ content: data.msg });
    } else {
      Toast.show({ content: data.msg });
    }
    loadLeverListData();
  };

  //加仓
  const addnum = async (param) => {
    const data = await leverApi.addnum({ uid, ...param });
    if (data.ok) {
      Toast.show({ content: data.msg });
    } else {
      Toast.show({ content: data.msg });
    }
    loadLeverListData();
  };

  //减仓
  const strutcnum = async (param) => {
    const data = await leverApi.strutcnum({ uid, ...param });
    if (data.ok) {
      Toast.show({ content: data.msg });
    } else {
      Toast.show({ content: data.msg });
    }
    loadLeverListData();
  };

  //设置亏损盈利值
  const editLossWin = async (param) => {
    const data = await leverApi.editLossWin({ uid, ...param });
    if (data.ok) {
      Toast.show({ content: data.msg });
    } else {
      Toast.show({ content: data.msg });
    }
    loadLeverListData();
  };

  const menuItems = [
    {
      text: translate(getText(propertyType === '2' ? '获得资产' : '提币')),
      icon: propertyType === '2' ? '/property/get.png' : '/newPropertyC/22.png',
      route: propertyType === '2' ? '/getproperty' : '/extractlist',
    },
    {
      text: translate(getText('地址')),
      icon: '/newPropertyC/111.png',
      route: '/addresslist',
    },
  ];

  return (
    <div className="md:p-6">
      <div className="w-full min-w-full xl:min-w-[1210px] max-w-[1400px] box-border my-0 mx-auto py-0 md:px-3">
        <div className=" css-11hb6xp">
          <div className=" css-xzvq9o exkbah61 flex flex-col gap-6">
            <div className="flex justify-between items-center xl:px-0 px-3">
              <div className="text-text-neutral-strong dark:text-dark-text-neutral-strong xl:heading-5 paragraph-2 font-bold">
                {translate('Wallet')}
              </div>
              <div className="flex gap-4">
                {menuItems.map((item) => (
                  <a
                    key={item.text}
                    className="cursor-pointer flex gap-1 items-center hover:text-primary-600 dark:hover:text-dark-primary-600 text-actions-primary-active dark:text-dark-actions-primary-active xl:paragraph-3 title-5 font-bold css-11vadmq e51lz330 relative"
                    data-testid="reku-link"
                    href={item.route}
                  >
                    <div
                      data-testid="reku-icon"
                      data-name="icon-PlusHistory"
                      className="css-17x35y8 e1d9s5l60"
                    >
                      {item.icon === 'geo-alt' ? (
                        <GeoAlt />
                      ) : (
                        <img
                          className="size-5"
                          src={item.icon}
                          alt={item.text}
                        />
                      )}
                    </div>
                    <span>{item.text}</span>
                  </a>
                ))}
              </div>
            </div>
            <div className="reku-new-theme css-1jj9cgs e1ss85sc2">
              <div className="css-7mhkks e1e6pa0j0">
                <h2 className="css-1k3u3dw e1ss85sc1">
                  {translate(getText('账户总资产'))}(USDT)
                </h2>
                <div className="min-h-6 xl:min-h-10 css-151naro e1e6pa0j0">
                  <h1 className="css-r4hobi e1ss85sc0">{usdt}</h1>
                  <div
                    data-testid="reku-icon"
                    data-name="icon-EyeShow"
                    className="w-fit h-fit inline-flex items-center justify-center"
                  >
                    {/* <EyeShow /> */}
                  </div>
                </div>
                <div className="!text-sm xl:!text-md !font-bold min-h-[14px] xl:min-h-5 text-text-neutral-subtle dark:text-dark-text-neutral-subtle css-nglks5 e1e6pa0j0">
                  ≈{usdt} USD
                  {/* <div className="flex">
                    (
                    <div className="css-8irbms e1skqjl32">
                      <div className="!text-sm xl:!text-md !font-bold css-1dhhk5g e1skqjl30">
                        0,00%
                      </div>
                    </div>
                    )
                  </div> */}
                </div>
              </div>
              <div className="reku-new-theme w-full">
                <div className="w-full overflow-x-auto overflow-y-hidden scrollbar-none">
                  <div className="flex justify-between items-center gap-3 w-fit xl:w-full">
                    <div role="button" className="relative h-fit">
                      <div
                        className="bg-background-card-main dark:bg-dark-background-card-main border border-outline-neutral-main dark:border-dark-outline-neutral-main rounded-md p-3 w-[300px] h-[160px] xl:h-[180px]"
                        style={{
                          backgroundImage: "url('/wallet/rp.png')",
                        }}
                      >
                        <div className="flex flex-col gap-2 xl:gap-3 justify-between h-full">
                          <div className="flex flex-col gap-2.5 xl:gap-5">
                            <span className="flex items-center gap-[6px] text-md font-semibold text-text-neutral-main dark:text-dark-text-neutral-main">
                              <div
                                data-testid="reku-icon"
                                data-name="icon-CurrencyRupiahCircle"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <CurrencyRupiahCircle />
                              </div>
                              USDT
                            </span>
                            <div className="flex flex-col gap-1">
                              <span className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                {translate(getText('可用'))}
                              </span>
                              <div>
                                <div className="text-heading-sm xl:text-heading-md text-text-neutral-main dark:text-dark-text-neutral-main">
                                  {usdt}
                                </div>
                                <div className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                  ≈{usdt * 1} USD
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-3 items-center justify-end h-8">
                            <button
                              onClick={() => {
                                navigate('/rechargelist?coin=usdt');
                              }}
                              className="border font-bold text-center focus:outline-none disabled:cursor-not-allowed flex items-center justify-center gap-2 bg-actions-primary-active dark:bg-dark-actions-primary-active border-actions-primary-active dark:border-dark-actions-primary-active hover:bg-actions-primary-hover dark:hover:bg-dark-actions-primary-hover text-text-neutral-onImageStrong dark:text-dark-text-neutral-onImageStrong disabled:bg-actions-primary-disabled dark:disabled:bg-dark-actions-primary-disabled disabled:border-actions-primary-disabled dark:disabled:border-dark-actions-primary-disabled p-2 text-xs rounded-sm w-full css-1wvvet6 e1hebimd0"
                            >
                              <div
                                data-testid="reku-icon"
                                data-name="icon-ArrowDown"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <ArrowDown />
                              </div>
                              {translate(getText('入金'))}
                            </button>
                            {/* <button
                              onClick={() => {
                                navigate("/extractlist");
                              }}
                              className="border font-bold text-center focus:outline-none disabled:cursor-not-allowed flex items-center justify-center gap-2 bg-transparent hover:bg-background-subtle-teal dark:hover:bg-dark-background-subtle-teal border-actions-primary-active dark:border-dark-actions-primary-active hover:border-actions-primary-hover dark:hover:border-dark-actions-primary-hover text-actions-primary-active dark:text-dark-actions-primary-active hover:text-actions-primary-hover dark:hover:text-dark-actions-primary-hover disabled:bg-transparent dark:disabled:bg-transparent hover:disabled:bg-transparent disabled:border-actions-grey-disabled dark:disabled:border-dark-actions-grey-disabled disabled:text-text-neutral-disabled dark:disabled:text-dark-text-neutral-disabled p-2 text-xs rounded-sm w-full css-1wvvet6 e1hebimd0"
                            >
                              <div
                                data-testid="reku-icon"
                                data-name="icon-ArrowUp"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <ArrowUp />
                              </div>
                              {translate(getText("出金"))}
                            </button> */}
                            <div
                              data-testid="reku-icon"
                              data-name="icon-ChevronRight"
                              className="w-fit h-fit inline-flex items-center justify-center"
                            >
                              <ChevronRight />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 h-[1px] translate-y-[1px] w-[calc(100%-4px)] bg-card-gradient blur-[1px]"></div>
                    </div>
                    {/* <div role="button" className="relative h-fit">
                      <div
                        className="bg-background-card-main dark:bg-dark-background-card-main border border-outline-neutral-main dark:border-dark-outline-neutral-main rounded-md p-3 w-[300px] h-[160px] xl:h-[180px]"
                        style={{
                          backgroundImage: "url('/wallet/ss.png')",
                        }}
                      >
                        <div className="flex flex-col gap-2 xl:gap-3 justify-between h-full">
                          <div className="flex flex-col gap-2.5 xl:gap-5">
                            <span className="flex items-center gap-[6px] text-md font-semibold text-text-neutral-main dark:text-dark-text-neutral-main">
                              <div
                                data-testid="reku-icon"
                                data-name="icon-CurrencyDollarCircle"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <CurrencyDollarCircle />
                              </div>
                              BTC
                            </span>
                            <div className="flex flex-col gap-1">
                              <span className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                {translate(getText('可用'))}
                              </span>
                              <div>
                                <div className="text-heading-sm xl:text-heading-md text-text-neutral-main dark:text-dark-text-neutral-main">
                                  {(usdt / btcClose)?.toFixed(8)}
                                </div>
                                <div className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                  ≈{usdt?.toFixed(2)} USD
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-3 items-center justify-end h-8">
                            <button
                              onClick={() => {
                                navigate('/rechargelist?coin=btc');
                              }}
                              className="border font-bold text-center focus:outline-none disabled:cursor-not-allowed flex items-center justify-center gap-2 bg-actions-secondary-active dark:bg-dark-actions-secondary-active border-actions-secondary-active dark:border-dark-actions-secondary-active hover:bg-actions-secondary-hover dark:hover:bg-dark-actions-secondary-hover text-text-neutral-onImageStrong dark:text-dark-text-neutral-onImageStrong disabled:bg-actions-secondary-disabled dark:disabled:bg-dark-actions-secondary-disabled disabled:border-actions-secondary-disabled dark:disabled:border-dark-actions-secondary-disabled p-2 text-xs rounded-sm w-full css-1wvvet6 e1hebimd0"
                            >
                              <div
                                data-testid="reku-icon"
                                data-name="icon-ArrowDown"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <ArrowDown />
                              </div>
                              {translate(getText('入金'))}
                            </button>
                            <div
                              data-testid="reku-icon"
                              data-name="icon-ChevronRight"
                              className="w-fit h-fit inline-flex items-center justify-center"
                            >
                              <ChevronRight />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 h-[1px] translate-y-[1px] w-[calc(100%-4px)] bg-card-gradient blur-[1px]"></div>
                    </div> */}
                    <div role="button" className="relative h-fit">
                      <div
                        className="bg-background-card-main dark:bg-dark-background-card-main border border-outline-neutral-main dark:border-dark-outline-neutral-main rounded-md p-3 w-[300px] h-[160px] xl:h-[180px]"
                        style={{
                          backgroundImage: "url('/wallet/eth.png')",
                        }}
                      >
                        <div className="flex flex-col gap-2 xl:gap-3 justify-between h-full">
                          <div className="flex flex-col gap-2.5 xl:gap-5">
                            <span className="flex items-center gap-[6px] text-md font-semibold text-text-neutral-main dark:text-dark-text-neutral-main">
                              <div
                                data-testid="reku-icon"
                                data-name="icon-CryptoCurrency"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <CryptoCurrency />
                              </div>
                              ETH
                            </span>
                            <div className="flex flex-col gap-1">
                              <span className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                {translate(getText('可用'))}
                              </span>
                              <div>
                                <div className="text-heading-sm xl:text-heading-md text-text-neutral-main dark:text-dark-text-neutral-main">
                                  {(usdt / ethClose)?.toFixed(8)}
                                </div>
                                <div className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                  ≈{usdt?.toFixed(2)} USD
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-3 items-center justify-end h-8">
                            <button
                              onClick={() => {
                                navigate('/rechargelist?coin=eth');
                              }}
                              className="border font-bold text-center focus:outline-none disabled:cursor-not-allowed flex items-center justify-center gap-2 bg-actions-primary-active dark:bg-dark-actions-primary-active border-actions-primary-active dark:border-dark-actions-primary-active hover:bg-actions-primary-hover dark:hover:bg-dark-actions-primary-hover text-text-neutral-onImageStrong dark:text-dark-text-neutral-onImageStrong disabled:bg-actions-primary-disabled dark:disabled:bg-dark-actions-primary-disabled disabled:border-actions-primary-disabled dark:disabled:border-dark-actions-primary-disabled p-2 text-xs rounded-sm w-full css-1wvvet6 e1hebimd0"
                            >
                              <div
                                data-testid="reku-icon"
                                data-name="icon-ArrowDown"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <ArrowDown />
                              </div>
                              {translate(getText('入金'))}
                            </button>
                            {/* <button
                              onClick={() => {
                                navigate("/extractlist");
                              }}
                              className="border font-bold text-center focus:outline-none disabled:cursor-not-allowed flex items-center justify-center gap-2 bg-transparent hover:bg-background-subtle-teal dark:hover:bg-dark-background-subtle-teal border-actions-primary-active dark:border-dark-actions-primary-active hover:border-actions-primary-hover dark:hover:border-dark-actions-primary-hover text-actions-primary-active dark:text-dark-actions-primary-active hover:text-actions-primary-hover dark:hover:text-dark-actions-primary-hover disabled:bg-transparent dark:disabled:bg-transparent hover:disabled:bg-transparent disabled:border-actions-grey-disabled dark:disabled:border-dark-actions-grey-disabled disabled:text-text-neutral-disabled dark:disabled:text-dark-text-neutral-disabled p-2 text-xs rounded-sm w-full css-1wvvet6 e1hebimd0"
                            >
                              <div
                                data-testid="reku-icon"
                                data-name="icon-ArrowUp"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <ArrowUp />
                              </div>
                              {translate(getText("出金"))}
                            </button> */}
                            <div
                              data-testid="reku-icon"
                              data-name="icon-ChevronRight"
                              className="w-fit h-fit inline-flex items-center justify-center"
                            >
                              <ChevronRight />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 h-[1px] translate-y-[1px] w-[calc(100%-4px)] bg-card-gradient blur-[1px]"></div>
                    </div>
                    <div className="relative h-fit w-[300px]" />
                    {/* <div role="button" className="relative h-fit">
                      <div
                        className="bg-background-card-main dark:bg-dark-background-card-main border border-outline-neutral-main dark:border-dark-outline-neutral-main rounded-md p-3 w-[300px] h-[160px] xl:h-[180px]"
                        style={{
                          backgroundImage:
                            "url('/_next/static/media/us-stock-background.58642057.webp')",
                        }}
                      >
                        <div className="flex flex-col gap-2 xl:gap-3 justify-between h-full">
                          <div className="flex flex-col gap-2.5 xl:gap-5">
                            <span className="flex items-center gap-[6px] text-md font-semibold text-text-neutral-main dark:text-dark-text-neutral-main">
                              <div
                                data-testid="reku-icon"
                                data-name="icon-BarChart"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <BarChart />
                              </div>
                              US Stock
                            </span>
                            <div className="flex flex-col gap-1">
                              <span className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                Balance
                              </span>
                              <div>
                                <div className="text-heading-sm xl:text-heading-md text-text-neutral-main dark:text-dark-text-neutral-main">
                                  $0
                                </div>
                                <div className="text-sm font-semibold leading-[18px] text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                                  ≈ Rp0
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-3 items-center justify-end h-8">
                            <div
                              data-testid="reku-icon"
                              data-name="icon-ChevronRight"
                              className="w-fit h-fit inline-flex items-center justify-center"
                            >
                              <ChevronRight />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 h-[1px] translate-y-[1px] w-[calc(100%-4px)] bg-card-gradient blur-[1px]"></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="reku-new-theme">
              <div className="relative h-fit">
                <div className="bg-background-card-main dark:bg-dark-background-card-main border border-outline-neutral-main dark:border-dark-outline-neutral-main rounded-md p-3 xl:p-6 mx-4 xl:mx-0">
                  <div className="flex flex-col gap-3">
                    <div className="flex justify-between">
                      <div className="flex gap-4">
                        <span
                          className={`text-md xl:text-lg font-bold cursor-pointer ${
                            activeTab === 'contract'
                              ? 'text-primary dark:text-dark-primary'
                              : 'text-text-neutral-strong dark:text-dark-text-neutral-strong'
                          }`}
                          onClick={() => setActiveTab('contract')}
                        >
                          {translate(getText('充值明细'))}
                        </span>
                        <span
                          className={`text-md xl:text-lg font-bold cursor-pointer ${
                            activeTab === 'perpetual'
                              ? 'text-primary dark:text-dark-primary'
                              : 'text-text-neutral-strong dark:text-dark-text-neutral-strong'
                          }`}
                          onClick={() => setActiveTab('perpetual')}
                        >
                          {translate(getText('提现明细'))}
                        </span>
                      </div>

                      <div className="relative hidden" data-headlessui-state="">
                        <button
                          id="headlessui-menu-button-:r8:"
                          type="button"
                          aria-haspopup="menu"
                          aria-expanded="false"
                          data-headlessui-state=""
                        >
                          <div className="flex items-center gap-1 text-md xl:text-lg font-bold text-primary dark:text-dark-primary">
                            <div className="relative flex items-center">
                              <div
                                data-testid="reku-icon"
                                data-name="icon-FilterFunnel"
                                className="w-fit h-fit inline-flex items-center justify-center"
                              >
                                <FilterFunnel />
                              </div>
                            </div>
                            Filter
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="!hidden reku-new-theme css-cjr3lq ezyzyd24">
                      <div className="!text-md xl:!text-lg css-4k5h5 ezyzyd23">
                        <div
                          className="css-yt7azb ezyzyd20"
                          style={{ opacity: 1 }}
                        ></div>
                        <span className="!text-md xl:!text-lg css-12kojux ezyzyd21">
                          Crypto
                        </span>
                      </div>
                      <div className=" css-4k5h5 ezyzyd23">
                        <span className="!text-md xl:!text-lg css-kja941 ezyzyd22">
                          US Stock
                        </span>
                      </div>
                    </div>
                    <div className="overflow-y-auto md:max-h-[calc(100vh-642px)]">
                      {activeTab === 'contract' && <RechargeOrderList />}
                      {activeTab === 'perpetual' && <ExtractOrderList />}
                      <div className="flex flex-col justify-center items-center gap-4">
                        <NoCoinAvailable />
                        <button
                          onClick={() =>
                            navigate(
                              activeTab === 'perpetual'
                                ? '/extractlist?coin=usdt'
                                : '/rechargelist?coin=usdt'
                            )
                          }
                          className="px-6 py-2.5 bg-[#4ea5ab] hover:bg-[#409196] active:bg-[#378186] text-white rounded-full font-medium transition-colors duration-200"
                        >
                          Start Now
                        </button>
                      </div>
                      {/* <div className="flex flex-col gap-2">
                        <span className="xl:text-body-lg text-text-neutral-subtle dark:text-dark-text-neutral-subtle">
                          You can add funds with your wallet account or another
                          wallet.
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 h-[1px] translate-y-[1px] w-[calc(100%-4px)] bg-card-gradient blur-[1px]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWallet;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd-mobile';
import { getText } from '../../../../utils/util';
import './index.css';
import { imageConfig } from '../../../../config/config';

export default function DownlandPage({ companyData }) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const title = localStorage.getItem('title');
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-4xl mx-auto px-4 pt-12">
        {/* 顶部背景图区域 - 重新设计 */}

        {/* Logo区域 - 调整样式 */}
        <div className="text-center  transform -translate-y-24">
          <div className="inline-block bg-white mt-8 rounded-2xl shadow-lg mb-4">
            <img
              src="/14021739970812_.pic.jpg"
              draggable="false"
              className="w-[100%]  h-auto object-contain rounded-2xl"
            />
          </div>
        </div>

        {/* 下载按钮区域 - 调整位置 */}
        <div className="max-w-md mx-auto space-y-4 transform -translate-y-12">
          {/* iOS下载按钮 */}
          <button
            onClick={() => {
              if (companyData?.iosDomain.includes('http')) {
                window.location.href = companyData?.iosDomain;
              }
            }}
            className="w-full bg-white hover:bg-gray-50 active:bg-gray-100 
              transition-all duration-200 rounded-xl shadow-lg hover:shadow-xl
              py-4 px-6 flex items-center justify-center space-x-3
              border border-gray-100 hover:scale-102"
          >
            <img
              src="/ios.png"
              draggable="false"
              className="w-6 h-6 object-contain"
            />
            <span className="text-lg font-medium text-gray-900">
              iOS {translate(getText('下載'))}
            </span>
          </button>

          {/* Android下载按钮 */}
          <button
            onClick={() => {
              if (companyData?.androidDomain.includes('http')) {
                window.location.href = companyData?.androidDomain;
              }
            }}
            className="w-full bg-white hover:bg-gray-50 active:bg-gray-100 
              transition-all duration-200 rounded-xl shadow-lg hover:shadow-xl
              py-4 px-6 flex items-center justify-center space-x-3
              border border-gray-100 hover:scale-102"
          >
            <img
              src="/anzhuo.png"
              draggable="false"
              className="w-6 h-6 object-contain"
            />
            <span className="text-lg font-medium text-gray-900">
              Android {translate(getText('下載'))}
            </span>
          </button>

          {/* 安装说明链接 */}
          <div className="text-center pt-4">
            <button
              onClick={() => {
                navigate('/downloadbook');
              }}
              className="text-blue-600 hover:text-blue-700 font-medium 
                underline-offset-4 hover:underline transition-colors"
            >
              {translate(getText('安装说明'))}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
